import { environment } from '../environments/environment';
var ɵ0 = {
    parseInput: 'LL LT',
    fullPickerInput: 'YYYY-MM-DD',
    datePickerInput: 'LL',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MM YYYY',
};
var AppModule = /** @class */ (function () {
    function AppModule() {
        if (ENV_OVERWRITE) {
            for (var key in ENV_OVERWRITE) {
                environment[key] = ENV_OVERWRITE[key];
            }
        }
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
