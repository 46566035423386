import { OnInit, OnDestroy, OnChanges } from '@angular/core';
import { paperService } from '../api-client/paper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from '../../app/config';
import { PaperModel } from '../models/paper.model';
import { ToastrService } from 'ngx-toastr';
import { paperFormBuilder } from './paper.form-builder';
import { OrganizationService } from '../api-client/organization.service';
import { AcademiaService } from '../api-client/academia.service';
var PaperSummaryComponent = /** @class */ (function () {
    function PaperSummaryComponent(_paperService, _route, _toster, _academiaService, _organizationSearvice, _formBuilder, _router) {
        var _this = this;
        this._paperService = _paperService;
        this._route = _route;
        this._toster = _toster;
        this._academiaService = _academiaService;
        this._organizationSearvice = _organizationSearvice;
        this._formBuilder = _formBuilder;
        this._router = _router;
        this.displayDetailIcon = true;
        this.paper = {};
        this.editMode = false;
        this.submitted = false;
        this.model = new PaperModel();
        this._academiaServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(function (_) {
            _this.sessions = _this._academiaService.getAcademicSession();
        });
        this._academiaDisciplineServiceSubscription = _academiaService.onDisciplinesUpdated.subscribe(function (_) {
            _this.disciplines = _this._academiaService.getDisciplines();
        });
        this._paperServiceSubscription = _paperService.onPapersUpdated.subscribe(function (_) {
            _this.paperDetails();
        });
    }
    Object.defineProperty(PaperSummaryComponent.prototype, "f", {
        get: function () { return this.paperForm.controls; },
        enumerable: true,
        configurable: true
    });
    PaperSummaryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.paperForm = this._formBuilder.getForm();
        this.sessions = this._academiaService.getAcademicSession();
        this.disciplines = this._academiaService.getDisciplines();
        this._route.params.subscribe(function (params) {
            _this.paperId = params.id;
            _this.resetForm();
            if (_this.paperId)
                _this.paperDetails();
        });
        this.env = config;
    };
    PaperSummaryComponent.prototype.ngOnChanges = function (changes) {
        this.paperDetails();
    };
    PaperSummaryComponent.prototype.paperDetails = function () {
        var _this = this;
        if (this.paperId == null)
            return;
        this._paperService.getPaper(this.paperId)
            .subscribe(function (result) {
            _this.paper = result;
            _this._formBuilder.setValues(result);
        });
    };
    PaperSummaryComponent.prototype.redirectToPaperDetails = function (paper) {
        this._router.navigateByUrl("/paper/" + paper.id);
    };
    PaperSummaryComponent.prototype.ngOnDestroy = function () {
        this._paperServiceSubscription.unsubscribe();
        this._academiaDisciplineServiceSubscription.unsubscribe();
        this._academiaServiceSubscription.unsubscribe();
    };
    PaperSummaryComponent.prototype.editPaper = function () {
        this.editMode = false;
    };
    PaperSummaryComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.paperForm.invalid) {
            return;
        }
        this.model.deserialize(this.paperForm.value);
        this._paperService.savePaper(this.model)
            .subscribe(function (response) {
            _this._toster.success("Paper saved");
            return false;
        }, function (error) {
            _this._toster.error(error.message);
        });
    };
    PaperSummaryComponent.prototype.resetForm = function () {
        this.submitted = false;
        this.paperForm.reset();
    };
    return PaperSummaryComponent;
}());
export { PaperSummaryComponent };
