import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportAsService } from 'ngx-export-as';
import { ToastrService } from 'ngx-toastr';
import { AcademiaService } from '../api-client/academia.service';
import { ExamService } from '../api-client/exam.service';
import { paperService } from '../api-client/paper.service';
import { config } from '../config';
import { UiService } from '../ui.service';
var StudentMarksSubmitComponent = /** @class */ (function () {
    function StudentMarksSubmitComponent(_paperService, _academiaService, _uiService, _route, _tosterService, _examService, _exportAsService, _router) {
        var _this = this;
        this._paperService = _paperService;
        this._academiaService = _academiaService;
        this._uiService = _uiService;
        this._route = _route;
        this._tosterService = _tosterService;
        this._examService = _examService;
        this._exportAsService = _exportAsService;
        this._router = _router;
        this.mode = "";
        this.env = config;
        this.submitMidTerm = false;
        this.submitEndTerm = false;
        this.isApply = false;
        this.invalidMarks = [];
        this.isPreview = true;
        this.invalidAbsents = [];
        this.isSubmit = false;
        this.isMidTermExpair = false;
        this.isEndTermExpair = false;
        this.alreadyMarksSubmit = false;
        this._academiaServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(function (_) {
            _this.academiaSessions = _this._academiaService.getAcademicSession();
            _this.populateStudents();
        });
    }
    StudentMarksSubmitComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.today = new Date();
        this._route.params.subscribe(function (params) {
            _this.paperId = params.id;
            if (_this.paperId) {
                _this.paperDetails();
                _this.populateStudents();
                _this.activeExam();
                _this.getPaperDetails(_this.paperId);
            }
        });
    };
    StudentMarksSubmitComponent.prototype.paperDetails = function () {
        var _this = this;
        if (this.paperId == null)
            return;
        this._paperService.getPaper(this.paperId)
            .subscribe(function (result) {
            _this.paper = result;
            _this._uiService.changeAppTitle.next("Paper: " + result.name);
            _this.session = _this._academiaService.getSessionDetails(result.academicSessionId);
            _this.paperSession = { "paperId": _this.paperId, "session": _this.session };
            _this.discipline = _this._academiaService.getDiscipline(result.disciplineId);
            _this._uiService.setpaperInDisciplineSession({ discipline: _this.discipline, session: _this.session });
            _this._uiService.setpaper(_this.paper);
        });
    };
    StudentMarksSubmitComponent.prototype.getPaperDetails = function (paperId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (this.paperId == null)
                    return [2 /*return*/];
                this._academiaService.getAssignedPaperDetails(paperId).subscribe(function (result) {
                    _this.paperData = result;
                    _this.endTermMarksSubmitedOn = _this.paperData.endTermMarksSubmitedOn;
                    _this.midTermMarksSubmitedOn = _this.paperData.midTermMarksSubmitedOn;
                    var midItems = _this.paperData.activeRequests.filter(function (x) { return x.type == 4; });
                    var endItems = _this.paperData.activeRequests.filter(function (x) { return x.type == 8; });
                    console.log('this.paperData', _this.paperData);
                    if (_this.paperData.allowSubmitMidTermMarks) {
                        _this.submitMidTerm = true;
                    }
                    else {
                        _this.submitMidTerm = false;
                    }
                    if (_this.paperData.allowSubmitEndTermMarks) {
                        _this.submitEndTerm = true;
                    }
                    else {
                        _this.submitEndTerm = false;
                    }
                    if (midItems.length > 0 && _this.paperData.allowSubmitMidTermMarks) {
                        _this.mode = 'mid';
                        _this.fullMarks = _this.paperData.paper.midTermAllocatedMarks;
                        _this.midTerms = midItems[0];
                        _this.midTermRemainingDays = _this.remainingDays(_this.midTerms.expiryOn);
                        if (_this.midTermMarksSubmitedOn) {
                            _this.alreadyMarksSubmit = false;
                        }
                        else {
                            _this.alreadyMarksSubmit = true;
                        }
                        if (_this.today > new Date(_this.midTerms.expiryOn)) {
                            _this.isMidTermExpair = true;
                        }
                    }
                    if (endItems.length > 0) {
                        _this.mode = 'end';
                        _this.fullMarks = _this.paperData.paper.endTermAllocatedMarks;
                        _this.endTerms = endItems[0];
                        _this.endTermRemainingDays = _this.remainingDays(_this.endTerms.expiryOn);
                        if (_this.endTermMarksSubmitedOn) {
                            _this.alreadyMarksSubmit = false;
                        }
                        else {
                            _this.alreadyMarksSubmit = true;
                        }
                        if (_this.today > new Date(_this.endTerms.expiryOn)) {
                            _this.isEndTermExpair = true;
                        }
                    }
                    if (_this.submitMidTerm && _this.submitEndTerm) {
                        _this.mode = '';
                        _this.fullMarks = null;
                    }
                }, function (error) {
                    _this._tosterService.error(error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    StudentMarksSubmitComponent.prototype.populateStudents = function () {
        var _this = this;
        if (this.paperId == null)
            return;
        this.paperInDisciplineSession = this._uiService.paperInDisciplineSession;
        this._academiaService.getPaperWiseStudents(this.paperId)
            .subscribe(function (result) {
            for (var i = 0; i < result.length; i++) {
                var session = _this.sessionDetails(result[i].academicSessionId);
                var name = '';
                if (session) {
                    name = session.name;
                }
                result[i].sessionName = name;
            }
            result.sort(function (x, y) {
                return x.registrationNumber.localeCompare(y.registrationNumber);
            });
            for (var k = 0; k < result.length; k++) {
                result[k].marks = 0;
                result[k].absent = 2;
            }
            _this.students = result;
        });
    };
    StudentMarksSubmitComponent.prototype.sessionDetails = function (sessionId) {
        if (this.academiaSessions == null)
            return null;
        var items = this.academiaSessions.filter(function (x) { return x.id == sessionId; });
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    };
    StudentMarksSubmitComponent.prototype.activeExam = function () {
        var _this = this;
        this._academiaService.getActiveExam().toPromise().then(function (result) {
            _this.activeExaminationId = result.id;
        });
    };
    StudentMarksSubmitComponent.prototype.submitMarks = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var i, isAbsent, studentParams, studentParams, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.mode) {
                            this._tosterService.error("Select Type");
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, , 9]);
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.students.length)) return [3 /*break*/, 5];
                        isAbsent = false;
                        if (this.students[i].absent == 0) {
                            isAbsent = true;
                        }
                        else {
                            if (this.students[i].marks == 0 && this.students[i].absent == 2) {
                                this._tosterService.error("Please select Absent or Zero Obtained");
                                return [2 /*return*/];
                            }
                            else {
                                isAbsent = false;
                            }
                        }
                        if (!this.students[i].marks || this.students[i].marks == '') {
                            this.students[i].marks = 0;
                        }
                        if (this.mode == 'mid') {
                            studentParams = {
                                studentId: this.students[i].id,
                                paperId: this.paper.id,
                                examinationId: this.activeExaminationId,
                                isMidTermMarks: true,
                                marks: parseFloat(this.students[i].marks),
                                studentAbsent: isAbsent,
                                incomplete: false,
                                mode: this.paperData.type
                            };
                        }
                        else if (this.mode == 'end') {
                            studentParams = {
                                studentId: this.students[i].id,
                                paperId: this.paper.id,
                                examinationId: this.activeExaminationId,
                                isMidTermMarks: false,
                                marks: parseFloat(this.students[i].marks),
                                studentAbsent: isAbsent,
                                incomplete: false,
                                mode: this.paperData.type
                            };
                        }
                        return [4 /*yield*/, this._examService.submitMarks(studentParams).toPromise()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5: return [4 /*yield*/, this._examService.sendSubmitMarksEmail(this.activeExaminationId, this.paper.id, 2, this.mode).toPromise()];
                    case 6:
                        _a.sent();
                        this._tosterService.success("Marks updated");
                        this.isApply = true;
                        this.isSubmit = true;
                        this.paperData.activeRequests.splice(0, 1);
                        return [4 /*yield*/, this.getPaperDetails(this.paperId)];
                    case 7:
                        _a.sent();
                        $('#OkModal').modal('toggle');
                        return [3 /*break*/, 9];
                    case 8:
                        e_1 = _a.sent();
                        this._tosterService.error(e_1);
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    StudentMarksSubmitComponent.prototype.clickOk = function () {
        $('#OkModal').modal('toggle');
        this._router.navigate(["/evaluter-papers"]);
    };
    StudentMarksSubmitComponent.prototype.changeType = function () {
        if (this.mode == 'end') {
            this.fullMarks = this.paperData.paper.endTermAllocatedMarks;
            if (this.endTermMarksSubmitedOn) {
                this.alreadyMarksSubmit = false;
            }
            else {
                this.isApply = false;
                this.isPreview = true;
                this.isSubmit = false;
                this.alreadyMarksSubmit = true;
            }
        }
        else if (this.mode == 'mid') {
            this.fullMarks = this.paperData.paper.midTermAllocatedMarks;
            if (this.midTermMarksSubmitedOn) {
                this.alreadyMarksSubmit = false;
            }
            else {
                this.isApply = false;
                this.isPreview = true;
                this.isSubmit = false;
                this.alreadyMarksSubmit = true;
            }
        }
        for (var k = 0; k < this.students.length; k++) {
            this.students[k].marks = 0;
            this.students[k].absent = 2;
        }
        this.invalidMarks = [];
        this.invalidAbsents = [];
    };
    StudentMarksSubmitComponent.prototype.enterMarks = function (e, index) {
        if (this.fullMarks != 'Select Type') {
            var num = Number(e.target.value) % 1 === 0;
            if (isNaN(e.target.value)) {
                this.isApply = true;
                if (!this.invalidMarks[index] || this.invalidMarks[index] == false) {
                    this.invalidMarks[index] = !this.invalidMarks[index];
                }
                this._tosterService.error("Enter valid marks");
            }
            else if (num === false) {
                var myArray = e.target.value.split(".");
                if (myArray.length > 1 && myArray[1] != 5) {
                    this.isApply = true;
                    if (!this.invalidMarks[index] || this.invalidMarks[index] == false) {
                        this.invalidMarks[index] = !this.invalidMarks[index];
                    }
                    this._tosterService.error("Enter valid . number");
                }
            }
            else if (e.target.value > this.fullMarks) {
                this.students[index].absent = 2;
                this.isApply = true;
                if (!this.invalidMarks[index] || this.invalidMarks[index] == false) {
                    this.invalidMarks[index] = !this.invalidMarks[index];
                }
                this.invalidAbsents[this.students[index].id] = false;
                this._tosterService.error("The entered marks " + e.target.value + " is greater than " + this.fullMarks);
            }
            else {
                this.invalidAbsents[this.students[index].id] = false;
                this.students[index].absent = 2;
                this.invalidMarks[index] = false;
                this.isApply = false;
                var isInvalid = this.invalidMarks.includes(true);
                if (isInvalid) {
                    this.isApply = true;
                }
            }
        }
    };
    StudentMarksSubmitComponent.prototype.changeIsPreview = function (preview) {
        this.isPreview = !preview;
    };
    StudentMarksSubmitComponent.prototype.showPreview = function (preview) {
        if (!this.mode) {
            this._tosterService.error("Select Type");
            return;
        }
        var tempAbsent = [];
        var tempInvalidMarks = [];
        for (var i = 0; i < this.students.length; i++) {
            if (!this.students[i].marks || this.students[i].marks == '') {
                this.students[i].marks = 0;
            }
            if (this.students[i].marks == 0 && this.students[i].absent == 2) {
                if (!this.invalidAbsents[this.students[i].id] || this.invalidAbsents[this.students[i].id] == false) {
                    this.invalidAbsents[this.students[i].id] = !this.invalidAbsents[this.students[i].id];
                }
                tempAbsent.push(i);
            }
            if (this.students[i].marks > this.fullMarks) {
                if (!this.invalidMarks[i] || this.invalidMarks[i] == false) {
                    this.invalidMarks[i] = !this.invalidMarks[i];
                }
                var isInvalid = this.invalidMarks.includes(true);
                if (isInvalid) {
                    this.isApply = true;
                }
                tempAbsent.push(i);
                this._tosterService.error("The entered marks " + this.students[i].marks + " is greater than " + this.fullMarks);
            }
        }
        if (tempAbsent.length == 0) {
            this.isPreview = !preview;
            this.invalidAbsents = [];
        }
        else {
            this._tosterService.error("Please select Absent or Zero Obtained or Valid marks");
        }
    };
    StudentMarksSubmitComponent.prototype.onItemChange = function (student) {
        this.invalidAbsents[student.id] = false;
    };
    StudentMarksSubmitComponent.prototype.remainingDays = function (date) {
        var todaydate = new Date().getTime();
        var expireDate = new Date(date).getTime();
        if (expireDate < todaydate)
            return "";
        var diffTime = Math.abs(new Date().valueOf() - new Date(date).valueOf());
        var days = diffTime / (24 * 60 * 60 * 1000);
        return Math.round(days);
    };
    return StudentMarksSubmitComponent;
}());
export { StudentMarksSubmitComponent };
