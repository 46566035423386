import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(_router) {
        this._router = _router;
    }
    AuthGuardService.prototype.canActivate = function (next, state) {
        var authDecode = sessionStorage.getItem("htoken");
        if (authDecode) {
            return true;
        }
        this._router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        return false;
    };
    AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.Router)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
