import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var LoginGuardService = /** @class */ (function () {
    function LoginGuardService(_router) {
        this._router = _router;
    }
    LoginGuardService.prototype.canActivate = function (next, state) {
        var authDecode = sessionStorage.getItem("htoken");
        if (authDecode) {
            return false;
        }
        return true;
    };
    LoginGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginGuardService_Factory() { return new LoginGuardService(i0.ɵɵinject(i1.Router)); }, token: LoginGuardService, providedIn: "root" });
    return LoginGuardService;
}());
export { LoginGuardService };
