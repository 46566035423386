import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { SessionService } from '../api-client/session.service';
import { ExportAsService } from 'ngx-export-as';
import { config } from '../config';
import { ToastrService } from 'ngx-toastr';
import { ExamService } from '../api-client/exam.service';
var PaperWiseStudentsComponent = /** @class */ (function () {
    function PaperWiseStudentsComponent(_academiaService, _route, _router, _uiService, _exportAsService, _tosterService, _sessionService, _examService) {
        var _this = this;
        this._academiaService = _academiaService;
        this._route = _route;
        this._router = _router;
        this._uiService = _uiService;
        this._exportAsService = _exportAsService;
        this._tosterService = _tosterService;
        this._sessionService = _sessionService;
        this._examService = _examService;
        this.config = config;
        this.mode = "";
        this._academiaServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(function (_) {
            _this.academiaSessions = _this._academiaService.getAcademicSession();
            _this.populateStudents();
        });
    }
    PaperWiseStudentsComponent.prototype.ngOnInit = function () {
        this.activeExam();
        this.academiaSessions = this._academiaService.getAcademicSession();
    };
    PaperWiseStudentsComponent.prototype.ngOnChanges = function (changes) {
        this.activeExam();
        this.populateStudents();
    };
    PaperWiseStudentsComponent.prototype.populateStudents = function () {
        var _this = this;
        if (this.paper == null)
            return;
        this.paperInDisciplineSession = this._uiService.paperInDisciplineSession;
        this._academiaService.getPaperWiseStudents(this.paper.id)
            .subscribe(function (result) {
            for (var i = 0; i < result.length; i++) {
                result[i].sessionName = _this.sessionDetails(result[i].academicSessionId).name;
            }
            result.sort(function (x, y) {
                return x.registrationNumber.localeCompare(y.registrationNumber);
            });
            for (var k = 0; k < result.length; k++) {
                result[k].marks = 0;
            }
            _this.students = result;
        });
    };
    PaperWiseStudentsComponent.prototype.ngOnDestroy = function () {
        this._academiaServiceSubscription.unsubscribe();
    };
    PaperWiseStudentsComponent.prototype.isEmpty = function () {
        return Object.keys(this.students).length == 0;
    };
    PaperWiseStudentsComponent.prototype.redirectTo = function (student) {
        this._router.navigate(["student/" + student.id]);
    };
    PaperWiseStudentsComponent.prototype.sessionDetails = function (sessionId) {
        if (this.academiaSessions == null)
            return null;
        var items = this.academiaSessions.filter(function (x) { return x.id == sessionId; });
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    };
    PaperWiseStudentsComponent.prototype.studentList = function (paperId) {
        //this._router.navigate(["student/"+student.id]);
        this._router.navigate([{
                outlets: { rightMainPopup: ['search-students', paperId] }
            }]);
    };
    PaperWiseStudentsComponent.prototype.export = function (type, elementId, name, arg) {
        var _this = this;
        this.midTerm = false;
        this.endTerm = false;
        if (arg == 'mid') {
            this.midTerm = true;
        }
        if (arg == 'end') {
            this.endTerm = true;
        }
        var configs = { type: type, elementId: elementId };
        setTimeout(function () {
            _this._exportAsService.save(configs, _this.paperInDisciplineSession.discipline.name + " " + _this.paper.code + "(" + _this.paperInDisciplineSession.session.name + ")").subscribe(function () {
            });
        }, 10);
    };
    PaperWiseStudentsComponent.prototype.selectAll = function (element) {
        for (var i = 0; i < this.students.length; i++) {
            if (element.checked) {
                this.students[i].checked = true;
            }
            else {
                this.students[i].checked = false;
            }
        }
    };
    PaperWiseStudentsComponent.prototype.selectIndividual = function (element, studentId) {
        for (var i = 0; i < this.students.length; i++) {
            if (this.students[i].id == studentId) {
                if (element.checked) {
                    this.students[i].checked = true;
                }
                else {
                    this.students[i].checked = false;
                }
            }
        }
    };
    PaperWiseStudentsComponent.prototype.unlinkStudents = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var student, count, i, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        student = this.students.filter(function (x) { return x.checked == true; });
                        if (student.length == 0) {
                            this._tosterService.error("Please select student(s)");
                            return [2 /*return*/];
                        }
                        count = 0;
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < this.students.length)) return [3 /*break*/, 4];
                        if (!this.students[i].checked) return [3 /*break*/, 3];
                        data = {
                            "studentId": this.students[i].id,
                            "paperCodes": [this.paper.code]
                        };
                        return [4 /*yield*/, this._academiaService.unsetStudentPaper(data).toPromise().then(function (result) {
                                count++;
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this._tosterService.success("Student(s) unlinked");
                        this.populateStudents();
                        return [2 /*return*/];
                }
            });
        });
    };
    PaperWiseStudentsComponent.prototype.linkStudents = function () {
        this._router.navigate([{
                outlets: { rightMainPopup: ['search-students', this.paper.id] }
            }]);
    };
    PaperWiseStudentsComponent.prototype.activeExam = function () {
        var _this = this;
        this._academiaService.getActiveExam().toPromise().then(function (result) {
            _this.activeExaminationId = result.id;
        });
    };
    PaperWiseStudentsComponent.prototype.submitMarks = function () {
        var _this = this;
        //console.log(this.activeExaminationId,this.students);return;
        if (!this.mode) {
            this._tosterService.error("Select Type");
            return;
        }
        for (var i = 0; i < this.students.length; i++) {
            var endAbsent = false;
            if (this.students[i].marks > 0) {
                endAbsent = false;
            }
            else {
                endAbsent = true;
            }
            var midAbsent = false;
            if (this.students[i].marks > 0) {
                midAbsent = false;
            }
            else {
                midAbsent = true;
            }
            if (this.mode == 'mid') {
                var studentParams = {
                    studentId: this.students[i].id,
                    paperId: this.paper.id,
                    //examinationId: this.historyData[i].children[k].examinationId,
                    examinationId: this.activeExaminationId,
                    isMidTermMarks: true,
                    marks: parseFloat(this.students[i].marks),
                    studentAbsent: midAbsent,
                    incomplete: false,
                };
            }
            else if (this.mode == 'end') {
                var studentParams = {
                    studentId: this.students[i].id,
                    paperId: this.paper.id,
                    //examinationId: this.historyData[i].children[k].examinationId,
                    examinationId: this.activeExaminationId,
                    isMidTermMarks: false,
                    marks: parseFloat(this.students[i].marks),
                    studentAbsent: endAbsent,
                    incomplete: false,
                };
            }
            //console.log(studentParams);return;
            this._examService.submitMarks(studentParams).subscribe(function (result) {
                _this._tosterService.success("Marks updated");
                return;
            });
        }
    };
    return PaperWiseStudentsComponent;
}());
export { PaperWiseStudentsComponent };
